import styled from "styled-components"
import { UITypography } from "../components/shared/atoms/UIComponents/UITypography/UITypography"

const ContainerSt = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3rem;
  text-align: center;
`

export default function Custom404() {
  return (
    <ContainerSt>
      <UITypography variant="h3">404</UITypography>
      <UITypography variant="h2">Whoops, this page could not be found</UITypography>
    </ContainerSt>
  )
}